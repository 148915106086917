import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/global/Layout";
import SectionParagraph from "../../components/ui/SectionParagraph";
import SectionTitle from "../../components/ui/SectionTitle";

const OdpadyKabliPage = () => {
  return (
    <Layout
      titleTemplate="%s"
      pageTitle="Skup odpadów kabli. Kable miedziane, wiązki samochodowe"
      pageDescription="Nasz zakład zajmuje się skupem kabli takich jak kable miedziane, kable uluminiowe, wiązka samochodowa, kable ołowiane i kable łączone."
    >
      <section className="container mx-auto px-5 py-16">
        <div className="flex flex-row gap-6 justify-between items-center flex-wrap lg:flex-nowrap space-x-8">
          <div className="md:w-1/2">
            <StaticImage
              src="../../images/services/kable.jpg"
              alt="Odpady kabli"
              width={800}
              height={600}
            />
          </div>
          <div className="md:w-1/2">
            <SectionTitle size="small">Odpady kabli</SectionTitle>
            <SectionParagraph className="mb-5">Skupujemy:</SectionParagraph>
            <ul className="list-disc list-inside text-xl text-gray-700">
              <li>kable miedziane</li>
              <li>kable aluminiowe</li>
              <li>wiązka samochodowa</li>
              <li>kable ołowiane</li>
              <li>kable łączone</li>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default OdpadyKabliPage;
